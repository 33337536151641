<template>
  <div class="readme-article">
    <div id="教务"></div>
    <h1 id="学员管理">一、学员管理</h1>
    <h2 id="学员资料查询">1.学员资料查询</h2>
    <h3 id="学员信息列表">1.1学员信息列表</h3>
    <p>
      查看正式学员列表，支持高级查询，多条件筛选学员信息，可查看学员详情，禁考等操作。
    </p>
    <p><img src="@/assets/img/education/jw1-1.png" /></p>
    <h3 id="学员信息详情">1.2学员信息详情</h3>
    <p>
      学员详情包括学员信息，文档资料，员工回访，财务流水，报考信息，考试成绩，积分/奖学金，操作日志等。
    </p>
    <p><img src="@/assets/img/education/jw1-2.png" /></p>
    <h2 id="学员树状查询">2.学员树状查询</h2>
    <p>
      提供按地区院校树状图查看学员信息，校区分布更清晰。
    </p>
     <p><img src="@/assets/img/education/jw1-3.png" /></p>
    <h2 id="添加学员">3.添加学员</h2>
    <p>
      员工可手动添加学员，必须填写姓名、手机、学生状态和班级；班级需选择班主任，期数以及专业。
    </p>
     <p><img src="@/assets/img/education/jw1-4.png" /></p>
  </div>
</template>

<script>
export default {
  name: "Education1-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>